<template>
  <app-header/>
    <Suspense>
      <template #default>
         <app-strategy/>
      </template>
      <template #fallback>
        <app-loading />
      </template>
    </Suspense>
  <app-footer/>
</template>

<script>
import AppFooter from '../../components/layouts/AppFooter.vue'
import AppHeader from '../../components/layouts/AppHeader.vue'
import AppStrategy from '../../components/Pages/GamesCategorys/AppStrategy.vue'
import AppLoading from '../../components/Spinners/AppLoading.vue'
import { Suspense } from "vue";
export default {
  components: { AppHeader, AppStrategy, AppFooter, AppLoading, Suspense },
   name: 'Strategy'
}
</script>

<style>

</style>